import type { Material, Design, Carat } from '../types'

const cartBaseUrl = 'https://miss-platinum.shop-pro.jp/?'
const materials: { name: string; value: Material }[] = [
    { name: 'プラチナ950', value: 'platinum' },
    { name: 'K18イエローゴールド', value: 'yellowgold' },
    { name: 'K18ピンクゴールド', value: 'pinkgold' },
    { name: 'ホワイトゴールド', value: 'whitegold' },
]
const material2s: { name: string; value: Material }[] = [
    { name: 'K18ゴールド', value: 'platinum' }, // HACK: marriagerings.json should have a field for material2
    { name: 'K5イエローゴールド', value: 'yellowgold' },
    { name: 'K5ピンクゴールド', value: 'pinkgold' },
]
const designs: { name: string; value: Design }[] = [
    { name: 'ストレート', value: 'straight' },
    { name: 'ウェーブ', value: 'wave' },
    { name: 'ダイヤモンド', value: 'diamond' },
    { name: 'エタニティ', value: 'eternity' },
]
const carats: { name: string; value: Carat }[] = [
    { name: '0.1カラット', value: 'carat_one' },
    { name: '0.2カラット', value: 'carat_two' },
    { name: '0.3カラット', value: 'carat_three' },
    { name: '0.5カラット', value: 'carat_five' },
]

export function numberWithCommas(x: number) {
    let y = x.toString()
    const pattern = /(-?\d+)(\d{3})/
    while (pattern.test(y)) y = y.replace(pattern, '$1,$2')
    return y
}

export function getMaterialLabel(value: Material) {
    return materials.find((v) => v.value === value)?.name
}

export function getMaterial2Label(value: Material) {
    return material2s.find((v) => v.value === value)?.name
}

export function getDesignLabel(value: Design) {
    return designs.find((v) => v.value === value)?.name
}

export function getCaratLabel(value: Carat) {
    return carats.find((v) => v.value === value)?.name
}

export const returnTop = () => {
    window.scrollTo({
        top: 0,
    })
}

export function getCartUrl(params: string) {
    return cartBaseUrl + params
}
