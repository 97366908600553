import { useTheme } from '@mui/material'
import { styled } from '@mui/system'
import { Link as _Link } from 'gatsby'
import React from 'react'

type LinkProps = {
    className?: string
    to: string
    margin?: string
}

export const Link: React.FC<LinkProps> = ({
    className,
    children,
    to,
    margin,
    ...props
}) => {
    const muiTheme = useTheme()
    return (
        <LinkWrapper
            className={className}
            to={to}
            theme={muiTheme}
            margin={margin}
            {...props}
        >
            {children}
        </LinkWrapper>
    )
}

const LinkWrapper = styled(_Link)<LinkProps>(({ theme, margin }) => ({
    color: theme.palette.action.active,
    margin: margin || 0,
}))

type ALinkProps = {
    className?: string
    href: string
    margin?: string
}

export const ALink: React.FC<ALinkProps> = ({
    className,
    children,
    href,
    margin,
}) => {
    const muiTheme = useTheme()
    return (
        <ALinkWrapper
            className={className}
            href={href}
            theme={muiTheme}
            margin={margin}
        >
            {children}
        </ALinkWrapper>
    )
}

const ALinkWrapper = styled('a')<ALinkProps>(({ theme, margin }) => ({
    color: theme.palette.action.active,
    margin: margin || 0,
}))
