import assert from 'assert'
import { useTheme } from '@mui/material'
import { styled } from '@mui/system'
import { Link } from 'gatsby'
import React from 'react'
import { numberWithCommas, getMaterialLabel } from '../../utils'
import { ListRingImage } from '../ListRingImage'
import { Txt } from '../Txt'
import type { Material } from '../../types'

type Ring = {
    code: string
    name: string
    material: Material
    price: number
    images: string[]
}

type Props = {
    rings: Ring[]
}

export const RingGallery: React.FC<Props> = ({ rings }) => {
    const muiTheme = useTheme()
    return (
        <Wrapper>
            {rings.map((ring, index) => {
                return (
                    <Ring key={index} to={'/ring/' + ring.code + '/'}>
                        <ListRingImage ring={ring} />
                        <RingInfo>
                            <PriceLine theme={muiTheme}>
                                <PriceTxt>
                                    {numberWithCommas(ring.price)}
                                </PriceTxt>
                                <Txt size="s">円~</Txt>
                            </PriceLine>
                            <MaterialNameTxt size="xs">
                                {getMaterialLabel(ring.material)}
                            </MaterialNameTxt>
                            <NameTxt theme={muiTheme} size="xs">
                                {ring.name}
                            </NameTxt>
                        </RingInfo>
                    </Ring>
                )
            })}
        </Wrapper>
    )
}

const Wrapper = styled('div')({
    maxWidth: '100%',
    overflowX: 'auto',
    display: 'flex',
})
const Ring = styled(Link)({
    display: 'block',
    width: '156px',
    margin: '8px 12px',
    textDecoration: 'none',
})
const RingInfo = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
})
const PriceLine = styled('p')(({ theme }) => ({
    margin: '0 0 4px',
    color: theme.palette.text.primary,
}))
const PriceTxt = styled(Txt)({
    fontWeight: 'bold',
    marginRight: '4px',
})
const MaterialNameTxt = styled(Txt)(({ theme }) => ({
    marginBottom: '4px',
    color: theme.palette.text.secondary,
}))
const NameTxt = styled(Txt)(({ theme }) => ({
    marginBottom: '8px',
    color: theme.palette.text.secondary,
}))

type NullableMarriageRing = {
    code?: string | null
    name?: string | null
    material?: string | null
    femalePrice?: number | null
    malePrice?: number | null
    images?: Array<string | null> | null
}

type NullableEngageRing = {
    code?: string | null
    name?: string | null
    material?: string | null
    price?: number | null
    images?: Array<string | null> | null
}

export function convertMarriageRing(ring: NullableMarriageRing): Ring {
    assert(ring.code)
    assert(ring.name)
    assert(ring.material)
    assert(ring.femalePrice)
    assert(ring.malePrice)
    assert(ring.images)
    const images = ring?.images?.map((image) => {
        assert(image)
        return image
    })
    return {
        code: ring.code,
        name: ring.name,
        material: ring.material as Material,
        price:
            ring.femalePrice < ring.malePrice
                ? ring.femalePrice
                : ring.malePrice,
        images: images,
    }
}

export function convertEngageRing(ring: NullableEngageRing): Ring {
    assert(ring.code)
    assert(ring.name)
    assert(ring.material)
    assert(ring.price)
    assert(ring.images)
    const images = ring?.images?.map((image) => {
        assert(image)
        return image
    })
    return {
        code: ring.code,
        name: ring.name,
        material: ring.material as Material,
        price: ring.price,
        images: images,
    }
}
