import assert from 'assert'
import { styled } from '@mui/system'
import { graphql, useStaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import { convertToBgImage } from 'gbimage-bridge'
import React from 'react'
import { Txt } from '../Txt'
import type { MarriageRingHeroImageQuery } from '../../../types/graphql-types'

type Prop = {
    isH1?: boolean
}

export const HeroImage: React.FC<Prop> = ({ isH1 }) => {
    const data: MarriageRingHeroImageQuery = useStaticQuery(graphql`
        query MarriageRingHeroImage {
            file(relativePath: { eq: "marriagering/marriagerings.jpg" }) {
                childImageSharp {
                    gatsbyImageData(formats: [WEBP])
                }
            }
        }
    `)

    const imageData = data.file?.childImageSharp?.gatsbyImageData
    assert(imageData)
    const bgImage = convertToBgImage(imageData)

    return (
        <Hero Tag="section" {...bgImage}>
            <HeroTextWrapper>
                {isH1 && (
                    <H1>
                        <Txt>結婚指輪一覧</Txt>
                    </H1>
                )}
                {!isH1 && <Txt>結婚指輪</Txt>}
                <Txt size="xs">marriage rings</Txt>
            </HeroTextWrapper>
        </Hero>
    )
}

const Hero = styled(BackgroundImage)({
    height: '235px',
    backgroundPosition: 'top',
    position: 'relative',
})
const HeroTextWrapper = styled('div')({
    position: 'absolute',
    top: '178px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    textShadow: '0 0 2px #ffffff',
})
const H1 = styled('h1')({
    margin: 0,
    fontWeight: 'normal',
})
