import { styled } from '@mui/system'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React from 'react'
import type { ListRingImageQuery } from '../../../types/graphql-types'
import type { Node } from 'gatsby'

type Ring = {
    code: string
    images: string[]
}

type Props = {
    ring: Ring
}

export const ListRingImage: React.FC<Props> = ({ ring }) => {
    const data: ListRingImageQuery = useStaticQuery(graphql`
        query ListRingImage {
            allFile(filter: { relativePath: { glob: "rings/*/{1,2}.jpg" } }) {
                edges {
                    node {
                        childImageSharp {
                            gatsbyImageData(
                                width: 200
                                height: 200
                                quality: 80
                                transformOptions: { fit: FILL }
                            )
                        }
                        relativePath
                    }
                }
            }
        }
    `)

    if (ring.images.length === 0) {
        return <></>
    }

    const imageFilePath =
        ring.images.length >= 2 ? ring.images[1] : ring.images[0]

    const node = data.allFile.edges.find((edge) => {
        return edge.node.relativePath === `rings/${ring.code}/${imageFilePath}`
    })?.node

    if (node === undefined) return <></>

    const image = getImage(node as unknown as Node)
    if (image === undefined) return <></>

    return (
        <ImgWrapper>
            <Img image={image} alt="" />
        </ImgWrapper>
    )
}

const ImgWrapper = styled('div')({
    display: 'flex',
    justifyContent: 'center',
})
const Img = styled(GatsbyImage)({
    width: '205px',
})
