import { Grid, useTheme } from '@mui/material'
import { styled } from '@mui/system'
import { graphql } from 'gatsby'
import React, { useMemo } from 'react'
import { Layout } from '../../../components/Layout'
import { Link } from '../../../components/Link'
import { HeroImage } from '../../../components/MarriageRings/HeroImage'
import {
    RingGallery,
    convertMarriageRing,
} from '../../../components/RingGallery'
import { SEO } from '../../../components/SEO'
import { Txt } from '../../../components/Txt'
import type { MarriageRingsByPriceQuery } from '../../../../types/graphql-types'
import type { PageProps } from 'gatsby'

export const Head = () => (
    <SEO
        title="安い結婚指輪・激安マリッジリングを予算にぴったりの値段から探せる｜ミスプラチナ"
        description="お客様の予算にぴったりのリーズナブルな値段・価格を選ぶことができ、結婚指輪（マリッジリング）が業界最安値（1万円台から）で購入いただけます。"
        page="marriagering/price"
    />
)

const IndexPage: React.FC<PageProps<MarriageRingsByPriceQuery>> = ({
    data,
}) => {
    const muiTheme = useTheme()
    const oneRings = useMemo(() => {
        return data.oneRings.edges.map((edge) => {
            return convertMarriageRing(edge.node)
        })
    }, [data])
    const twoRings = useMemo(() => {
        return data.twoRings.edges.map((edge) => {
            return convertMarriageRing(edge.node)
        })
    }, [data])
    const threeRings = useMemo(() => {
        return data.threeRings.edges.map((edge) => {
            return convertMarriageRing(edge.node)
        })
    }, [data])

    return (
        <Layout
            breadcrumbs={[
                { name: '安い結婚指輪・マリッジリング', path: 'marriagering/' },
                { name: '値段から探す' },
            ]}
        >
            <Grid container spacing={0} component="main">
                <Grid item xs={12}>
                    <HeroImage />
                </Grid>
                <Grid item sm={2} />
                <Grid item sm={8} xs={12}>
                    <TitleWrapper>
                        <H1>
                            <Txt size="l">値段</Txt>
                            <Txt size="s">から結婚指輪を探す</Txt>
                        </H1>
                        <Description theme={muiTheme}>
                            <Txt size="xs">
                                ミスプラチナではお客様の予算に応じた様々な価格帯の格安マリッジリングを取り扱っています。
                            </Txt>
                        </Description>
                    </TitleWrapper>
                </Grid>
                <Grid item xs={12}>
                    <CategorizeRings>
                        <H2>
                            <Txt size="l">1万円台</Txt>
                            <Txt size="s">の結婚指輪</Txt>
                        </H2>
                        <RingGallery rings={oneRings} />
                        <MoreLinkWrapper>
                            <Link to="/marriagering/price/1万円/">
                                1万円台の結婚指輪をもっと見る
                            </Link>
                        </MoreLinkWrapper>
                    </CategorizeRings>
                    <CategorizeRings>
                        <H2>
                            <Txt size="l">2万円台</Txt>
                            <Txt size="s">の結婚指輪</Txt>
                        </H2>
                        <RingGallery rings={twoRings} />
                        <MoreLinkWrapper>
                            <Link to="/marriagering/price/2万円/">
                                2万円台の結婚指輪をもっと見る
                            </Link>
                        </MoreLinkWrapper>
                    </CategorizeRings>
                    <CategorizeRings>
                        <H2>
                            <Txt size="l">3万円台以上</Txt>
                            <Txt size="s">の結婚指輪</Txt>
                        </H2>
                        <RingGallery rings={threeRings} />
                        <MoreLinkWrapper>
                            <Link to="/marriagering/price/3万円以上/">
                                3万円台以上の結婚指輪をもっと見る
                            </Link>
                        </MoreLinkWrapper>
                    </CategorizeRings>
                </Grid>
            </Grid>
        </Layout>
    )
}

export const query = graphql`
    query MarriageRingsByPrice {
        oneRings: allMarriageringsJson(
            filter: { femalePrice: { lt: 20000 } }
            limit: 5
            sort: { fields: femalePrice, order: ASC }
        ) {
            edges {
                node {
                    code
                    name
                    material
                    femalePrice
                    malePrice
                    images
                }
            }
        }
        twoRings: allMarriageringsJson(
            filter: { femalePrice: { lt: 30000, gte: 20000 } }
            limit: 5
            sort: { fields: femalePrice, order: ASC }
        ) {
            edges {
                node {
                    code
                    name
                    material
                    femalePrice
                    malePrice
                    images
                }
            }
        }
        threeRings: allMarriageringsJson(
            filter: { femalePrice: { gte: 30000 } }
            limit: 5
            sort: { fields: femalePrice, order: ASC }
        ) {
            edges {
                node {
                    code
                    name
                    material
                    femalePrice
                    malePrice
                    images
                }
            }
        }
    }
`

const TitleWrapper = styled('div')({
    padding: '20px',
})
const H1 = styled('h1')({
    margin: '0 0 8px',
    lineHeight: 1,
})
const H2 = styled('h2')({
    margin: '0 0 8px',
    lineHeight: 1,
    padding: '10px',
    borderBottom: '1px solid #aaaaaa',
})
const Description = styled('p')(({ theme }) => ({
    margin: '0 0 20px',
    color: theme.palette.text.secondary,
}))
const CategorizeRings = styled('section')({
    margin: '10px 10px 28px',
})
const MoreLinkWrapper = styled('div')({
    display: 'flex',
    justifyContent: 'end',
    margin: '20px 10px',
})

export default IndexPage
